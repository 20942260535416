<template>
  <div>
    <!-- picA1BA95FDF9A802F0A5370E04EA406362.jpg -->
    <img class="rounded img-large" src="">
    <p>
      <router-link :to="{ name: 'newsletter' }">Sign up</router-link>
      for the free LEGO Universe email newsletter before <b>Monday, May 18th</b> if you haven't already! The next newsletter will land in subscribers' inboxes a few days later, and it will include a collectible animation of this battle-training mech!
    </p>
    <p>
      Regular readers of "Create digital designs the LEGO Universe way" have been wowed by the evolution of this robot, but no member of the public has seen the awesome sight of the mech as it prepares for battle!
    </p>
    <p>
      And the upcoming animation is just one of the exclusives available only to LEGO Universe newsletter subscribers! The newsletter is also the spot for goodies like sneak peeks at new characters from LEGO Universe, new VIP codes and inside scoops on the game's development!
    </p>
  </div>
</template>
